<template>
  <b-modal id="softwareModal" :title="title" size="xl" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="5">
          <label>Softwarename:</label>
          <b-form-input v-model="softwareName" placeholder="Softwarename..." trim/>
        </b-col>
        <b-col cols="2">
          <label>Inaktiv: <b-icon-question-circle-fill id="tt-active" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="inactive" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-active" triggers="hover">Inaktive Software</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="5">
          <label>Searchstring:</label>
          <b-form-input v-model="search" placeholder="Searchstring..." trim/>
        </b-col>
        <b-col cols="2">
          <label>Neutral: <b-icon-question-circle-fill id="tt-neutral" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="neutral" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-neutral" triggers="hover">Lizenzen werden immer erlaubt gezählt</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="3">
          <label>Gruppe:</label>
          <b-form-select v-model="groupSelect.selected" :options="groupSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Gruppe auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <label>Warnhinweis: <b-icon-question-circle-fill id="tt-warning" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="countWarning" number type="number" :disabled="this.infinite"/>
          <b-tooltip target="tt-warning" triggers="hover">Anzahl ab wann eine Warnung angezeigt wird</b-tooltip>
        </b-col>
        <b-col cols="2">
          <label>Maximum: <b-icon-question-circle-fill id="tt-max" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="countMax" number type="number" :disabled="this.infinite"/>
          <b-tooltip target="tt-max" triggers="hover">Maximal erlaubte Anzahl an Lizenzen</b-tooltip>
        </b-col>
        <b-col cols="2">
          <label>Unbegrenzt: <b-icon-question-circle-fill id="tt-infinite" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="infinite" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-infinite" triggers="hover">Lizenzzählung ohne Maximalwert</b-tooltip>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import {getRequest, putRequest} from "@/modules/requests";

export default {
  name: "SoftwareModal",
  props: ['method', 'data', 'groupId'],
  data() {
    return {
      id: null,
      softwareName: '',
      countMax: 0,
      countWarning: 0,
      infinite: false,
      neutral: false,
      inactive: false,
      search: '',
      expire: dayjs().format('DD.MM.YYYY'),
      datepickerDate: dayjs().format('YYYY-MM-DD'),
      groupSelect: {
        selected: null,
        options: []
      },
      loadingOkButton: false
    }
  },
  watch: {
    data() {
      this.onShow()
    },
    method() {
      this.onShow()
    },
    groupId() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateSoftware();
          break;
        case 'add':
          this.addSoftware();
          break;
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    inputChange(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.datepickerDate = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChange(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.expire = dayjs(event).format('DD.MM.YYYY')
      }
    },
    async onShow() {
      await this.getGroups()
      if(this.method === 'edit' && this.data) {
        this.id = this.data.id
        this.softwareName = this.data.displayname
        this.countWarning = this.data.count_warning
        this.countMax = this.data.count_max
        this.infinite = this.data.infinite
        this.neutral = this.data.neutral
        this.inactive = (this.data.software) ? !this.data.software.active : false
        this.search = (this.data.software) ? this.data.software.search : ''
        this.expire = (this.data.expire) ? dayjs(this.data.expire).format('DD.MM.YYYY') : dayjs().format('DD.MM.YYYY')
        this.datepickerDate = (this.data.expire) ? dayjs(this.data.expire).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')
        this.groupSelect.selected = this.data.groupId
      }
      else {
        this.id = null
        this.softwareName = ''
        this.countMax =  0
        this.countWarning = 0
        this.infinite = false
        this.neutral = false
        this.inactive = false
        this.search = ''
        this.expire = dayjs().format('DD.MM.YYYY')
        this.datepickerDate = dayjs().format('YYYY-MM-DD')
        this.groupSelect.selected = this.groupId
      }
    },
    async getGroups() {
      await getRequest('licenses/fg/license-groups', null, this)
          .then((response) => {
            this.groupSelect.options = []
            response.data.forEach(element => {
              this.groupSelect.options.push({
                value: element.id,
                text: element.displayname
              })
            })
          })
    },
    async addSoftware() {
      this.loadingOkButton = true
      let data = {
        displayname: this.softwareName,
        count_max: this.countMax,
        count_warning: this.countWarning,
        infinite: this.infinite,
        neutral: this.neutral,
        expire: this.expire,
        search: this.search,
        inactive: this.inactive,
        group_id: this.groupSelect.selected
      }
      await putRequest('licenses/fg/software/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('softwareModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateSoftware() {
      this.loadingOkButton = true
      let data = {
        software_id: this.id,
        displayname: this.softwareName,
        count_max: this.countMax,
        count_warning: this.countWarning,
        infinite: this.infinite,
        neutral: this.neutral,
        expire: this.expire,
        search: this.search,
        inactive: this.inactive,
        group_id: this.groupSelect.selected
      }
      await putRequest('licenses/fg/software/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('softwareModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    ...mapGetters(['userThemeId']),
    iconVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Software ändern'
      }
      return 'Software hinzufügen'
    },
    disabledButton() {
      return !(this.softwareName && this.search && this.expire && this.groupSelect.selected)
    },
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
