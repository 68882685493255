<template>
  <b-modal id="commandSyncModal" :title="title" size="xl" @show="onShow">
    <div v-if="method === 'add'">
      <b-form  @submit.stop.prevent="">
        <b-form-row>
          <b-col cols="2">
            <label>Image:</label>
            <b-form-select v-model="imageSelect.selected" :options="imageSelect.options">
              <template #first>
                <b-form-select-option :value="null" disabled>-- Image --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="5" class="ml-4">
            <label>Name der EXE:</label>
            <b-input-group :append="inputAppend">
              <b-form-input v-model="executableName" placeholder="Name der EXE..." trim/>
            </b-input-group>
          </b-col>
          <b-col cols="2">
            <label>Type:</label>
            <b-form-select v-model="typeSelect.selected" :options="typeSelect.options">
              <template #first>
                <b-form-select-option :value="null" disabled>-- Type --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="auto" class="ml-auto" align-self="end">
            <b-button :variant="variantSuccess" :disabled="disabledAddCommand" @click="addCommand">
              <b-spinner v-show="loadingAddCommandButton" small/>
              Command anlegen
            </b-button>
          </b-col>
        </b-form-row>
        <b-form-row class="mt-2">
          <b-col cols="2">
            <label>Dateigröße (MB):</label>
            <b-form-input v-model="sizePacked" number min="1" type="number"/>
          </b-col>
          <b-col cols="5" class="ml-4">
            <label>Dateiname:</label>
            <b-input-group append=".7z">
              <b-form-input v-model="fileName" placeholder="Name der Datei..." trim/>
            </b-input-group>
          </b-col>
          <b-col cols="auto" class="ml-auto" align-self="end">
            <b-button :variant="variantSuccess" :disabled="disabledAddSync" @click="addSync">
              <b-spinner v-show="loadingAddSyncButton" small/>
              Sync anlegen
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </div>
    <div v-else>
      <b-form v-if="task === 'command'" @submit.stop.prevent="">
        <b-form-row>
          <b-col cols="2">
            <label>Image:</label>
            <b-form-select v-model="imageSelect.selected" :options="imageSelect.options">
              <template #first>
                <b-form-select-option :value="null" disabled>-- Image --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="5" class="ml-4">
            <label>Name der EXE:</label>
            <b-input-group :append="inputAppend">
              <b-form-input v-model="executableName" placeholder="Name der EXE..." trim/>
            </b-input-group>
          </b-col>
          <b-col cols="auto" class="ml-auto mr-3">
            <label>Inaktiv:</label>
            <b-form-checkbox v-model="inactive" :value="true" :unchecked-value="false"/>
          </b-col>
        </b-form-row>
      </b-form>
      <b-form v-else-if="task === 'sync'" @submit.stop.prevent="">
        <b-form-row>
          <b-col cols="2">
            <label>Dateigröße (MB):</label>
            <b-form-input v-model="sizePacked" number min="1" type="number"/>
          </b-col>
          <b-col cols="5" class="ml-4">
            <label>Dateiname:</label>
            <b-input-group append=".7z">
              <b-form-input v-model="fileName" placeholder="Name der Datei..." trim/>
            </b-input-group>
          </b-col>
          <b-col cols="auto" class="ml-auto mr-3">
            <label>Inaktiv:</label>
            <b-form-checkbox v-model="inactive" :value="true" :unchecked-value="false"/>
          </b-col>
        </b-form-row>
      </b-form>
    </div>
    <template #modal-footer="{ close }">
      <b-button :variant="(method === 'edit') ? 'danger' : 'primary'" @click="close()">
        {{ (method === 'edit') ? 'Abbrechen' : 'Schließen' }}
      </b-button>
      <b-button v-if="method === 'edit' && task === 'sync'" :variant="variantSuccess" :disabled="disabledChangeSync"
                @click="updateSync">
        <b-spinner v-show="loadingUpdateButton" small/>
        Ändern
      </b-button>
      <b-button v-else-if="method === 'edit' && task === 'command'" :variant="variantSuccess" :disabled="disabledChangeCommand"
                @click="updateCommand">
        <b-spinner v-show="loadingUpdateButton" small/>
        Ändern
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {toastDanger} from "@/modules/status";
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "CommandSyncModal",
  props: ['method', 'data'],
  data() {
    return {
      id: null,
      task: null,
      fileName: '',
      executableName: '',
      sizePacked: 0,
      inactive: false,
      typeSelect: {
        selected: null,
        options: [
          {value: 'install', text: 'Installation'},
          {value: 'deinstall', text: 'Deinstallation'}
        ]
      },
      imageSelect: {
        selected: null,
        options: []
      },
      loadingUpdateButton: false,
      loadingAddSyncButton: false,
      loadingAddCommandButton: false
    }
  },
  watch: {
    data() {
      this.onShow()
    },
    method() {
      this.onShow()
    }
  },
  methods: {
    onShow() {
      if(this.method === 'edit' && this.data) {
        this.id = this.data.id
        this.task = this.data.task
        this.inactive = this.data.inactive
        this.sizePacked = (this.data.size_packed) ? this.data.size_packed : 0
        this.typeSelect.selected = (this.data.type) ? this.data.type : null
        this.imageSelect.selected = (this.data.image_id) ? this.data.image_id : null
        this.executableName = (this.data.command_win) ?this.data.command_win.replace(/\.exe.*/, '') : ''
        this.fileName = (this.data.file_name) ? this.data.file_name.replace('.7z', '') : ''
      }
      else {
        this.id = null
        this.task = null
        this.fileName = ''
        this.executableName = ''
        this.sizePacked = 0
        this.inactive = false
        this.typeSelect.selected = null
        this.imageSelect.selected = null
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    async getImages() {
      await getRequest('/images', null, this)
          .then((response) => {
            this.imageSelect.options = []
            response.data.forEach(element => {
              this.imageSelect.options.push({value: element.id, text: element.displayname})
            })
          })
    },
    async addSync() {
      this.loadingAddSyncButton = true
      let data = {
        software_id: this.data.softwareId,
        file_name: this.fileName + '.7z',
        size_packed: this.sizePacked
      }
      await putRequest('licenses/fg/software/sync-add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.')
          .then(() => {
            this.loadingAddSyncButton = false
            this.refresh()
          })
          .catch((error) => {
            this.loadingAddSyncButton = false
            toastDanger(this, error.response.data.errors.software_id[0]);
          })
    },
    async updateSync() {
      this.loadingUpdateButton = true
      let data = {
        sync_id: this.id,
        file_name: this.fileName + '.7z',
        size_packed: this.sizePacked,
        inactive: this.inactive
      }
      await putRequest('licenses/fg/software/sync-update', data, this, 'Eintrag wurde erfolgreich geändert.', 'Eintrag konnte nicht geändert werden.')
          .then(() => {
            this.loadingUpdateButton = false
            this.refresh()
            this.$bvModal.hide('commandSyncModal')
          })
          .catch(() => {
            this.loadingUpdateButton = false
          })
    },
    async addCommand() {
      this.loadingAddCommandButton = true
      let data = {
        software_id: this.data.softwareId,
        command_win: this.executableName,
        type: this.typeSelect.selected,
        image_id: this.imageSelect.selected
      }
      await putRequest('licenses/fg/software/command-add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.')
          .then(() => {
            this.loadingAddCommandButton = false
            this.refresh()
          })
          .catch((error) => {
            this.loadingAddCommandButton = false
            toastDanger(this, error.response.data.errors.software_id[0])
          })
    },
    async updateCommand() {
      this.loadingUpdateButton = true
      let data = {
        command_id: this.id,
        command_win: this.executableName,
        inactive: this.inactive,
        image_id: this.imageSelect.selected
      }
      await putRequest('licenses/fg/software/command-update', data, this, 'Eintrag wurde erfolgreich geändert.', 'Eintrag konnte nicht geändert werden.')
          .then(() => {
            this.loadingUpdateButton = false
            this.refresh()
            this.$bvModal.hide('commandSyncModal')
          })
          .catch(() => {
            this.loadingUpdateButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 2) { return 'danger-deuteranopia' }
      return 'danger'
    },
    title() {
      if(this.method === 'add') {
        return 'Commands/Syncs hinzufügen'
      }
      return 'Commands/Syncs ändern'
    },
    disabledAddSync() {
      return !(this.fileName && this.sizePacked > 0)
    },
    disabledAddCommand() {
      return !(this.executableName && this.typeSelect.selected && this.imageSelect.selected)
    },
    disabledChangeSync() {
      return !(this.fileName && this.sizePacked > 0)
    },
    disabledChangeCommand() {
      return !(this.executableName)
    },
    inputAppend() {
      if(this.typeSelect.selected === 'install') {
        return '.exe:-install'
      }
      else if(this.typeSelect.selected === 'deinstall') {
        return '.exe:-uninstall'
      }
      return ''
    }
  },
  created() {
    this.getImages()
  }
}
</script>

<style lang="scss" scoped>
</style>
