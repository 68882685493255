<template>
  <div v-if="software.neutral">
    <b-row>
      <b-col cols="auto" class="ml-auto">
        <b-badge v-if="software.infinite" :id="'tt-neutral-' + software.id" variant="neutral">{{ software.count }}</b-badge>
        <b-badge v-else-if="software.count >= software.count_max" :id="'tt-neutral-' + software.id" :variant="variantDanger">
          {{ software.count }}/{{ software.count_max }}
        </b-badge>
        <b-badge v-else :id="'tt-neutral-' + software.id" variant="neutral">{{ software.count }}/{{ software.count_max }}</b-badge>
        <b-tooltip :target="'tt-neutral-'  + software.id" triggers="hover">Neutral</b-tooltip>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="auto" class="ml-auto pr-0">
        <b-badge v-if="software.infinite" :variant="variantSuccess">{{ software.count }}</b-badge>
        <b-badge v-else-if="software.count >= software.count_max" :variant="variantDanger">
          {{ software.count }}/{{ software.count_max }}
        </b-badge>
        <b-badge v-else-if="software.count >= software.count_warning" :variant="variantWarning">
          {{ software.count }}/{{ software.count_max }}
        </b-badge>
        <b-badge v-else :variant="variantSuccess">{{ software.count }}/{{ software.count_max }}</b-badge>
      </b-col>
      <b-col cols="5" class="text-right pl-0">
        <b-badge :id="'tt-warnung-' + software.id" :variant="variantDanger" class="ml-3">
          {{ software.count_disallowed }}
        </b-badge>
        <b-badge :id="'tt-fg-note-' + software.id" :variant="variantWarning" class="ml-1 border-danger">
          {{ software.count_noticed }}
        </b-badge>
        <b-badge :id="'tt-commented-' + software.id" :variant="variantWarning" class="ml-1">
          {{ software.count_commented }}
        </b-badge>
        <b-badge :id="'tt-verified-' + software.id" :variant="variantWarning" class="ml-1 border-success">
          {{ software.count_verified }}
        </b-badge>
        <b-badge :id="'tt-allowed-' + software.id" :variant="variantSuccess" class="ml-1">
          {{ software.count_allowed }}
        </b-badge>
        <b-tooltip :target="'tt-warnung-' + software.id" triggers="hover">Warnung</b-tooltip>
        <b-tooltip :target="'tt-fg-note-' + software.id" triggers="hover">FG-Anmerkung</b-tooltip>
        <b-tooltip :target="'tt-commented-' + software.id" triggers="hover">Kommentiert</b-tooltip>
        <b-tooltip :target="'tt-verified-' + software.id" triggers="hover">Verifiziert</b-tooltip>
        <b-tooltip :target="'tt-allowed-' + software.id" triggers="hover">Erlaubt</b-tooltip>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BadgeCounterSoftware",
  props: ['software'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 2) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 2) { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userThemeId === 2) { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/Licenses';
</style>
